import React from "react"
import { graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Layout from "../components/layout"
import Head from "../components/head"

export const query = graphql`
  query($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      slug
      title
      date(formatString: "DD.MM.YYYY")
      body {
        raw
      }
    }
  }
`

const PostTemplate = props => {
  return (
    <Layout>
      <Head title="Post" />
      <h2>{props.data.contentfulPost.title}</h2>
      <p>{props.data.contentfulPost.date}</p>
      {renderRichText(props.data.contentfulPost.body)}
    </Layout>
  )
}

export default PostTemplate
